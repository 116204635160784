/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// ========================================================================

/* For use in src/lib/core/theming/_palette.scss */
// $md-my-primary: (
//     50 : #e4e7e9,
//     100 : #bdc2c8,
//     200 : #919aa4,
//     300 : #64717f,
//     400 : #435263,
//     500 : #223448,
//     600 : #1e2f41,
//     700 : #192738,
//     800 : #142130,
//     900 : #0c1521,
//     A100 : #619cff,
//     A200 : #2e7cff,
//     A400 : #005efa,
//     A700 : #0054e0,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #ffffff,
//         400 : #ffffff,
//         500 : #ffffff,
//         600 : #ffffff,
//         700 : #ffffff,
//         800 : #ffffff,
//         900 : #ffffff,
//         A100 : #000000,
//         A200 : #ffffff,
//         A400 : #ffffff,
//         A700 : #ffffff,
//     )
// );

// $md-my-accent: (
//     50 : #ffffff,
//     100 : #ffffff,
//     200 : #e5eaf1,
//     300 : #b7c5d8,
//     400 : #a3b5cd,
//     500 : #8fa5c2,
//     600 : #7b95b7,
//     700 : #6785ac,
//     800 : #56769f,
//     900 : #4c678b,
//     A100 : #ffffff,
//     A200 : #eff4fb,
//     A400 : #a7bedd,
//     A700 : #9cb2cf,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #000000,
//         400 : #000000,
//         500 : #000000,
//         600 : #000000,
//         700 : #000000,
//         800 : #ffffff,
//         900 : #ffffff,
//         A100 : #000000,
//         A200 : #000000,
//         A400 : #000000,
//         A700 : #000000,
//     )
// );

// $md-my-warn: (
//     50 : #f4ebee,
//     100 : #d8bac3,
//     200 : #c496a4,
//     300 : #ab697c,
//     400 : #9d586d,
//     500 : #894d5f,
//     600 : #754251,
//     700 : #623744,
//     800 : #4e2c36,
//     900 : #3b2129,
//     A100 : #f6dfe6,
//     A200 : #e18ea7,
//     A400 : #b9506f,
//     A700 : #9f5068,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #ffffff,
//         400 : #ffffff,
//         500 : #ffffff,
//         600 : #ffffff,
//         700 : #ffffff,
//         800 : #ffffff,
//         900 : #ffffff,
//         A100 : #000000,
//         A200 : #000000,
//         A400 : #ffffff,
//         A700 : #ffffff,
//     )
// );

// @use '@angular/material' as mat;

// @include mat.core();

// $my-primary: mat.define-palette($md-my-primary);
// $my-accent: mat.define-palette($md-my-accent);
// $my-warn: mat.define-palette($md-my-warn);

// $my-theme: mat.define-light-theme((
//  color: (
//    primary: $my-primary,
//    accent: $my-accent,
//    warn: $my-warn
//  ),
//  typography: mat.define-typography-config(),
//  density: 0,
// ));

// @include mat.all-component-themes($my-theme);

// @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// ====================================================================================

// http://mcg.mbitson.com/#!?mcgpalette0=%23000000&mcgpalette1=%23cdcece&themename=mcgtheme
$md-my-primary: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-my-accent: (
    50 : #f9f9f9,
    100 : #f0f0f0,
    200 : #e6e7e7,
    300 : #dcdddd,
    400 : #d5d5d5,
    500 : #cdcece,
    600 : #c8c9c9,
    700 : #c1c2c2,
    800 : #babcbc,
    900 : #aeb0b0,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #e5ffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-my-warn: (
    50 : #f4ebee,
    100 : #d8bac3,
    200 : #c496a4,
    300 : #ab697c,
    400 : #9d586d,
    500 : #894d5f,
    600 : #754251,
    700 : #623744,
    800 : #4e2c36,
    900 : #3b2129,
    A100 : #f6dfe6,
    A200 : #e18ea7,
    A400 : #b9506f,
    A700 : #9f5068,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.define-palette($md-my-primary);
$my-accent: mat.define-palette($md-my-accent);
$my-warn: mat.define-palette($md-my-warn);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-warn
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

@include mat.all-component-themes($my-theme);


@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



